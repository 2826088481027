.edu{
    margin: 0px;
    padding: 0px;
}
.edu-item1{
    margin: 1px;
    margin-top: 20px;
    padding: 0px;
}
.edu-item2{
    margin: 1px;
    padding: 0px;
}