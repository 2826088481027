.card{
    width: 300px;
    opacity: 1;
    padding: 10px;
    margin: 30px;
    border-radius: 20px;
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: scale 0.5s;
}
.project img{
    width: 100%;
    border-radius: 20px;
}
.project{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
code{
    background-color: aliceblue;
    color: green;
    margin: 10px;
    padding: 3px;
}
.icons{
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}
.fas{
    color: #3498db;
}



.icon-box {
	padding: 30px;
	position: relative;
	overflow: hidden;
	background: #fff;
	box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
	transition: all 0.3s ease-in-out;
	border-radius: 8px;
	z-index: 1;
}

.icon-box::before {
	content: '';
	position: absolute;
	background: #e1f0fa;
	right: -60px;
	top: -40px;
	width: 100px;
	height: 100px;
	border-radius: 50px;
	transition: all 0.3s;
	z-index: -1;
}

.icon-box:hover::before {
	background: #3498db;
	right: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border-radius: 0px;
}

.icon-box:hover .title a,
.icon-box:hover .black{
	color: #fff;
}
.icon-box:hover .fas{
	color: #fff;
}

.icon-box:hover .icon {
	background: #fff;
}